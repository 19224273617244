<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p>
        Using Slater's rules, determine the effective nuclear charge,
        <stemble-latex :content="'$Z_{\\mathrm{eff}}$'" />
        , for the following electrons in a
        <chemical-latex :content="atomName" />
        atom.
      </p>
      <br />

      A <span class="text-bold">4p</span> electron:
      <calculation-input
        v-model="input1"
        :prepend-text="'$Z_{\\mathrm{eff}}$'"
        :disabled="!allowEditing"
      />
      <br />

      A <span class="text-bold">3d</span> electron:
      <calculation-input
        v-model="input2"
        :prepend-text="'$Z_{\\mathrm{eff}}$'"
        :disabled="!allowEditing"
      />
      <br />

      A <span class="text-bold">2s</span> electron:

      <calculation-input
        v-model="input3"
        :prepend-text="'$Z_{\\mathrm{eff}}$'"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '../displayers/ChemicalLatex';

export default {
  name: 'Question31a',
  components: {
    ChemicalLatex,
    CalculationInput,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: ['input1', 'input2', 'input3'],
      input1: null,
      input2: null,
      input3: null,
    };
  },
  computed: {
    chemicalElement() {
      return this.taskState.getValueBySymbol('element1').content;
    },
    atomName() {
      return `\\ce{${this.chemicalElement.name}}`;
    },
  },
};
</script>
